import { serverApi } from 'classes/Config';

export function getQueryParams(qs) {
  qs = qs.split('+').join(' ');

  var params = {},
    tokens,
    re = /[?&]?([^=]+)=([^&]*)/g;

  while ((tokens = re.exec(qs))) {
    params[decodeURIComponent(tokens[1])] = decodeURIComponent(tokens[2]);
  }

  return params;
}

export function route(routeName, query) {
  let routerMatch = serverApi.routes[routeName] || routeName;

  if (query && typeof query === 'string') {
    routerMatch += '/' + query;
  }

  // query is an object, replace :key with the value
  if (query && typeof query === 'object') {
    Object.keys(query).forEach((key) => {
      routerMatch = routerMatch.replace(`:${key}`, query[key]);
    });
  }

  return routerMatch;
}

export function querify(routeName, params) {
  let computedRoute = route(routeName);

  let query = '';
  for (const key in params) {
    if (params[key] !== undefined) {
      query += `${key}=${params[key]}&`;
    }
  }
  if (query) {
    query = query.slice(0, -1);
    const s = computedRoute.indexOf('?') > -1 ? '&' : '?';
    computedRoute += `${s}${query}`;
  }

  return computedRoute;
}
export function detailHistory(path, router) {
  const {
    query: { pageHistory },
  } = router;

  let append = '';

  if (pageHistory) {
    append = `?pageHistory=${pageHistory}`;
  }

  return path + append;
}
