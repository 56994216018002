import nookies, { destroyCookie, parseCookies, setCookie } from 'nookies';
import { decodeBase64 } from 'classes/Str';

const TOKEN = '__kredibel_token__';

export function token(ctx) {
  const token = auth(ctx).token ?? null;
  if (!token) return null;
  return decodeBase64(token);
}

/**
 * Get token from the request context
 *
 * @param {object} ctx can be context, req, string or false
 * @returns object
 */
export default function auth(ctx) {
  // if ctx is false, destroy the cookie
  if (ctx === false) {
    return destroyCookie({}, TOKEN, {
      path: '/',
    });
  }

  // if ctx is undefined, get token from the browser cookie
  if (!ctx) {
    const cookies = parseCookies();

    return { token: cookies[TOKEN] };
  }

  // if ctx it string then it is the token to be set
  if (typeof ctx === 'string') {
    setCookie(null, TOKEN, ctx, {
      path: '/',
    });
  }

  // if ctx is a req and it has a session, return the token
  if (ctx.session && ctx.session.user) {
    return {
      token: ctx.session.user.token,
    };
  }

  // if ctx has a req and req has a session, return the token
  if (ctx.req && ctx.req.session && ctx.req.session.user) {
    return {
      token: ctx.req.session.user.token,
    };
  }

  // if ctx is object then it is the request context
  // and get token from the object.cookies
  if (typeof ctx === 'object' && 'cookies' in ctx) {
    return { token: ctx.cookies[TOKEN] };
  }

  // else, get token from the context cookies
  const cookies = nookies.get(ctx);

  return {
    token: cookies[TOKEN],
  };
}
